// web.ts
// entry point for web component
import type {
  Config,
  CallbackAction,
  Action,
  Settings,
} from '@brainfish-ai/widgets-common';
import {
  ActionType,
  injectBrainfishInWindow,
  WidgetType,
} from '@brainfish-ai/widgets-common';

import { fetchWithErrorHandling } from './utils/fetchWithErrorHandling';
import { isIOS14OrBelow } from './utils/isIOS14OrBelow';
import { sendBrainfishWidgetError } from './utils/sendBrainfishError';

declare global {
  interface Window {
    Brainfish: {
      Widgets: {
        init: (params: {
          widgetKey: string;
          overrides?: Pick<
            Settings,
            'nextBestActions' | 'bodyActionButtons' | 'footerActionButtons'
          >;
        }) => void;
      };
      SearchWidget: {
        initStandard: (config: Config) => void;
      };
      HelpWidget: {
        initPopup: (config: Config) => void;
        close: (trigger?: string) => void;
        open: (trigger?: string) => void;
        toggle: (trigger?: string) => void;
      };
    };
  }
}

const API_HOST = import.meta.env.VITE_API_HOST;

function mapActionButtons(
  actionButtons: (CallbackAction | Action)[]
): (CallbackAction | Action)[] {
  return actionButtons.map((action) => {
    if (action.type === ActionType.CALLBACK && !!action.value) {
      return {
        ...action,
        value: new Function(`return ${action.value}`)(),
      };
    }
    return action;
  });
}

const transformConfig = ({
  config,
  apiKey,
}: {
  config: Config;
  apiKey: string;
}) => {
  if (!!config.settings?.bodyActionButtons) {
    config.settings.bodyActionButtons = mapActionButtons(
      config.settings.bodyActionButtons
    );
  }
  if (!!config.settings?.footerActionButtons) {
    config.settings.footerActionButtons = mapActionButtons(
      config.settings.footerActionButtons
    );
  }
  if (!!config.settings?.nextBestActions) {
    config.settings.nextBestActions = mapActionButtons(
      config.settings.nextBestActions
    );
  }

  return {
    ...config,
    apiHost: `${API_HOST}`,
    widgetMode: config.widgetType,
    apiKey,
  };
};

const useUMD = isIOS14OrBelow();

let scriptLoaded = false;

function loadSearchWidgetScript(
  widgetKey: string,
  url: string,
  callback?: (module?: any) => void
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (document.getElementById('brainfish-widget')) {
      callback && callback();
      return resolve();
    }

    const scriptType = useUMD ? 'text/javascript' : 'module';

    const fragment = document.createDocumentFragment();
    const script = document.createElement('script');
    script.id = 'brainfish-widget';
    script.src = url;
    script.type = scriptType;
    script.async = true;

    script.onload = () => {
      setTimeout(() => {
        const module = (window as any).Brainfish;

        if (module) {
          callback && callback(module);
          resolve();
        } else {
          const error = new Error('Failed to load Brainfish module');
          sendBrainfishWidgetError(error, 'Failed to load script', widgetKey);
          reject(error);
        }
      }, 200);
    };

    script.onerror = (event) => {
      const errorEvent = event as ErrorEvent;
      const errorDetails = {
        message: `Failed to load script: ${url}`,
        type: errorEvent.type,
        fileName: errorEvent.filename,
        lineNumber: errorEvent.lineno,
        columnNumber: errorEvent.colno,
        error: errorEvent.error ? errorEvent.error.toString() : 'Unknown error',
      };
      reject(new Error(JSON.stringify(errorDetails)));
    };

    // TODO: Remove this once we have a better solution for UMD
    if (!useUMD) {
      fragment.appendChild(script);
      document.head.appendChild(fragment);
    }
  });
}

const init = async ({
  widgetKey,
  overrides,
}: {
  widgetKey: string;
  overrides?: Pick<
    Settings,
    'nextBestActions' | 'bodyActionButtons' | 'footerActionButtons'
  >;
}) => {
  try {
    const endpoint = `${API_HOST}/api/searchWidgets.getConfigByKey`;
    const configResponse = await fetchWithErrorHandling(endpoint, widgetKey);
    const config = transformConfig({
      config: configResponse.config,
      apiKey: widgetKey,
    });
    const version = config.version || 'latest';

    if (overrides) {
      const { nextBestActions, bodyActionButtons, footerActionButtons } =
        overrides;

      const updateActions = (
        configActions: (Action | CallbackAction)[] = [],
        overrideActions: (Action | CallbackAction)[]
      ) => {
        try {
          // Create a map from the configActions array, using the action label as the key
          const actionMap = new Map(
            configActions.map((action) => [action.label, action])
          );

          // Update the actionMap with the overrideActions.
          // If an action with the same label already exists in the map, it will be updated.
          // If it doesn't exist, it will be added to the map.
          overrideActions.forEach((action) =>
            actionMap.set(action.label, action)
          );

          // Convert the map back to an array and return it
          return Array.from(actionMap.values());
        } catch (error) {
          console.warn(
            `Error processing actions: overrides: ${JSON.stringify(overrides)}`,
            error
          );
          return [];
        }
      };

      if (config.settings) {
        config.settings = {
          ...config.settings,
          nextBestActions: nextBestActions
            ? updateActions(config.settings.nextBestActions, nextBestActions)
            : config.settings.nextBestActions,
          bodyActionButtons: bodyActionButtons
            ? updateActions(
                config.settings.bodyActionButtons,
                bodyActionButtons
              )
            : config.settings.bodyActionButtons,
          footerActionButtons: footerActionButtons
            ? updateActions(
                config.settings.footerActionButtons,
                footerActionButtons
              )
            : config.settings.footerActionButtons,
        };
      }
    }

    const url = `https://cdn.jsdelivr.net/npm/@brainfish-ai/search-widget@${version}/dist/web.js`;
    const scriptUrl = useUMD ? url.replace('web.js', 'web.umd.js') : url;

    let widget;

    try {
      await loadSearchWidgetScript(widgetKey, scriptUrl, (exportedModule) => {
        setTimeout(() => {
          if (!scriptLoaded) {
            sendBrainfishWidgetError(
              new Error('Widget load failed'),
              'Widget load failed',
              widgetKey
            );
          }
        }, 3000);

        // Access the widget through the global scope, assuming it's exposed there
        if (exportedModule) {
          widget = exportedModule;
          scriptLoaded = true;
        } else {
          widget = (window as any).Brainfish;
        }
        try {
          switch (config.widgetType) {
            case WidgetType.Searchbar:
            case 'Search':
              widget.SearchWidget && widget.SearchWidget.initStandard(config);
              break;
            case WidgetType.Sidebar:
            case 'slide-over': // for backwards compatibility
              widget.HelpWidget && widget.HelpWidget.initPopup(config);
              break;
            default:
              return;
          }
        } catch (error) {
          sendBrainfishWidgetError(
            error,
            `Error loading widget: ${url}`,
            widgetKey
          );
        }
      });

      if (Brainfish && widget) {
        injectBrainfishInWindow(widget);
      }

      // Use the widget...
    } catch (error) {
      throw new Error(`Error loading widget: ${url}`, { cause: error });
    }
  } catch (error) {
    sendBrainfishWidgetError(error, (error as Error).message, widgetKey);
  }
};

const Brainfish = {
  Widgets: { init },
  SearchWidget: {
    initStandard: (config: Config) => {
      if (typeof window === 'undefined') return;
      if (window.Brainfish && window.Brainfish.SearchWidget) {
        window.Brainfish.SearchWidget.initStandard(config);
      } else {
        console.warn(
          'Brainfish is not initialized. Please call Brainfish.Widgets.init() first.'
        );
      }
    },
  },
  HelpWidget: {
    initPopup: (config: Config) => {
      if (typeof window === 'undefined') return;
      if (window.Brainfish && window.Brainfish.HelpWidget) {
        window.Brainfish.HelpWidget.initPopup(config);
      } else {
        console.warn(
          'Brainfish is not initialized. Please call Brainfish.Widgets.init() first.'
        );
      }
    },
    close: (trigger?: string) => {
      if (typeof window === 'undefined') return;
      if (window.Brainfish && window.Brainfish.HelpWidget) {
        window.Brainfish.HelpWidget.close(trigger);
      } else {
        console.warn(
          'Brainfish is not initialized. Please call Brainfish.Widgets.init() first.'
        );
      }
    },
    open: (trigger?: string) => {
      if (typeof window === 'undefined') return;
      if (window.Brainfish && window.Brainfish.HelpWidget) {
        window.Brainfish.HelpWidget.open(trigger);
      } else {
        console.warn(
          'Brainfish is not initialized. Please call Brainfish.Widgets.init() first.'
        );
      }
    },
    toggle: (trigger?: string) => {
      if (typeof window === 'undefined') return;
      if (window.Brainfish && window.Brainfish.HelpWidget) {
        window.Brainfish.HelpWidget.toggle(trigger);
      } else {
        console.warn(
          'Brainfish is not initialized. Please call Brainfish.Widgets.init() first.'
        );
      }
    },
  },
};

if (typeof window !== 'undefined') {
  window.Brainfish = { Widgets: { init: init } } as any;
}

export default Brainfish;
