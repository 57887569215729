import { BrainfishWidgetError } from '@brainfish-ai/widgets-common';

const API_HOST = import.meta.env.VITE_API_HOST;

export const sendErrors = (error: BrainfishWidgetError, widgetKey: string) => {
  // only send errors if browser is not a headless browser
  if (!navigator || navigator.userAgent.toLowerCase().includes('headless')) {
    return;
  }

  fetch(`${API_HOST}/api/trackError.widget.create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': widgetKey,
    },
    body: JSON.stringify({
      error: error.message,
      stack: error.stack,
      cause: error.cause,
    }),
  });
};
