import { BrainfishWidgetError } from '@brainfish-ai/widgets-common';
import { sendErrors } from './sendErrors';

export async function fetchWithErrorHandling(
  endpoint: string,
  widgetKey: string,
): Promise<any> {
  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'api-key': widgetKey,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`API call failed with status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      if (!data) {
        throw new Error('Error loading config: empty response');
      }
      return data;
    })
    .catch((error) => {
      sendErrors(
        new BrainfishWidgetError(`Error fetching config`, { cause: error }),
        widgetKey,
      );
    });
}
